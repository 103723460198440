button {
    border: none;
    border-radius: 40px;
    background: $gravity-green;
    color: #ffffff;
    cursor: pointer;
    float: right;
    height: 40px;
    letter-spacing: 1.5px;
    padding: 0px 2rem;
    -webkit-font-smoothing: subpixel-antialiased;
    text-transform: uppercase;
    transition: 0.15s;
    white-space: nowrap;
    width: fit-content;

    &:hover {
        background: lighten($gravity-green, 10);
    }

    &:active {
        background: darken($gravity-green, 5);
    }

    &:disabled {
        background: $gray;
    }
}


button.submit {
    @media #{$desktop} {
        bottom: 3rem;
        float: none;
        right: 3rem;
    }
}

